import React from 'react'
import { graphql } from 'gatsby'
import { FormattedMessage } from 'react-intl'
import Layout from '../components/layout'
import SEO from '../components/seo'
import styles from './privacypolicy.module.scss'

const PrivacyPolicyPage = ({ pageContext: { locale, hrefLangs }, data }) => {
  const content = data.allContentfulPrivacyPolicy.edges[0].node
  return (
    <Layout locale={locale}>
      <SEO
        title={content.title}
        description={content.title}
        hrefLangs={hrefLangs}
      />
      <main role="main" className={styles.main}>
      <div className="wrapper">
        <section className={styles.privacypolicy}>
          <h1>
            <FormattedMessage id="privacypolicy" />
          </h1>
          <div
            dangerouslySetInnerHTML={{
              __html: content.content.childMarkdownRemark.html,
            }}
          ></div>
        </section>
      </div>
      </main>
    </Layout>
  )
}

export default PrivacyPolicyPage

export const query = graphql`
  query($localeLanguage: String!) {
    allContentfulPrivacyPolicy(
      filter: { node_locale: { eq: $localeLanguage } }
    ) {
      edges {
        node {
          title
          content {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
